<template>
  <div class="col-md-12" style="margin-top:70px;">
    <div class="row" style="justify-content: center;">
      <div class="col-md-2" style="text-align: center;" v-if="secretario">
        <h6 style="border:1px solid #000;width:100%;"></h6>
        <h6>
          Secretário(a)
        </h6>
      </div>

      <div class="col-md-2" style="text-align: center;" v-if="diretor">
        <h6 style="border:1px solid #000;width:100%;"></h6>
        <h6>
          Diretor(a)
        </h6>
      </div>

      <div class="col-md-2" style="text-align: center;" v-if="supervisor">
        <h6 style="border:1px solid #000;width:100%;"></h6>
        <h6>
          Supervisor(a)
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent ({
  components:{},
  props:{
    secretario:{
      default: '1'
    },
    diretor:{
      default: '1'
    },
    supervisor:{
      default: '1'
    }
  },
  data(){
    return{}
  },
  async beforeMount() {},
  methods: {}
});
</script>
