<template>
  <div class="row " v-for="n in ficha" :key="n"  :class="[ numero > 1 ? 'borda' : '' ]">
    <header01 :config="config" :head="n" />
    <!--
      Observação,:
      Andre visualisou arquivo excel de italva.
        No cabeçario da tabela de Areas de conhecimento ele nao quer

      | 1°B | Pres. | 2°B .....

      ele pediu que essa parte seja igual ao de cardoso mesmo sendo pra italva
      |     1° Periodo Letivo            |
      | Notas | Aulas dadas | frequencia |
    -->
    <div class="col-md-12">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th></th>
            <th colspan="3">1° Período Letivo</th>
            <th colspan="3">2° Período Letivo</th>
            <th colspan="3">3° Período Letivo</th>
            <th colspan="3">4° Período Letivo</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th>Áreas de Conhecimento</th>
            <th colspan="1">Nota</th>
            <th colspan="1">Aulas dadas</th>
            <th colspan="1">Frequência</th>
            <th colspan="1">Nota</th>
            <th colspan="1">Aulas dadas</th>
            <th colspan="1">Frequência</th>
            <th colspan="1">Nota</th>
            <th colspan="1">Aulas dadas</th>
            <th colspan="1">Frequência</th>
            <th colspan="1">Nota</th>
            <th colspan="1">Aulas dadas</th>
            <th colspan="1">Frequência</th>
            <th>Nota Final</th>
            <th>Observações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="a in n.componentes_curriculares" :key="a">
            <td> {{ a.nome_componente }} </td>
            <td> {{ a.p1_letivo.nota }} </td>
            <td> {{ a.p1_letivo.aulas_dadas }} </td>
            <td> {{ a.p1_letivo.presenca }} </td>
            <td> {{ a.p2_letivo.nota }} </td>
            <td> {{ a.p2_letivo.aulas_dadas }} </td>
            <td> {{ a.p2_letivo.presenca }} </td>
            <td> {{ a.p3_letivo.nota }} </td>
            <td> {{ a.p3_letivo.aulas_dadas }} </td>
            <td> {{ a.p3_letivo.presenca }} </td>
            <td> {{ a.p4_letivo.nota }} </td>
            <td> {{ a.p4_letivo.aulas_dadas }} </td>
            <td> {{ a.p4_letivo.presenca }} </td>
            <td> {{ a.nota_final }} </td>
            <td> {{ a.observacao }} </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="col-md-6">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Controle de frequência</th>
            <th>{{ n.controle_de_frequencia.p1 }}</th>
            <th>{{ n.controle_de_frequencia.p2 }}</th>
            <th>{{ n.controle_de_frequencia.p3 }}</th>
            <th>{{ n.controle_de_frequencia.p4 }}</th>
          </tr>
        </thead>
      </table>
    </div>
    <div class="col-md-6">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Total</th>
            <th>{{ n.controle_de_frequencia.total}}</th>
          </tr>
          <tr>
            <th>Total de Freq.</th>
            <th>{{ n.controle_de_frequencia.total_frequencia }}</th>
          </tr>
          <tr>
            <th>Perc. de Freq(%)</th>
            <th>{{ n.controle_de_frequencia.percentual_de_frequencia }}</th>
          </tr>
        </thead>
      </table>
    </div>

    <div class="col-md-12">
      <h6>
        À vista do(s) resultado(s) o/a aluno(a) foi considerado(a): {{ n.aluno_foi_considerado }}
      </h6>
      <h6>
        Data: {{ n.data }}
      </h6>
    </div>

    <assinatura secretario="1" diretor="1" supervisor="1" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import header01 from "./components/header01"
import assinatura from "./components/assinaturas"

import { Fichaindividual } from '../../../services/fichaindividual.service.js'

export default defineComponent ({
  components:{
    assinatura,header01
  },
  props:{
    dados:[],
  },
  data(){
    return{
      config:[],
      ficha:[],
      numero: 0
    }
  },
  async beforeMount() {
    this.config = {
      diasletivos: 1,
      escola: 1,
      aluno: 1,
      sexo: 1,
      mae:1,
      pai:1,
      nascimento:1,
      naturalidade:1,
      endereco:1,
      anoletivo:1,
      segmento:0,
      serie:1,
      turma:1,
      turno:1,
    }

    this.buscarFichaIndividual();
  },
  methods: {
    async buscarFichaIndividual(){
      //this.dados
      const res = await Fichaindividual.json(0);
      console.log(res)
      this.ficha = res;
      this.numero = this.ficha.length
    }
  }
});
</script>

<style scoped>
.borda{
  border: 1px solid #dee2e6;
  padding-top: 35px;
  padding-bottom: 35px;
  margin-bottom: 10px;
}
</style>
