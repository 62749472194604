<template>
  <div>
    <modelo01 v-if="modelo === '1'" :dados="this.dados" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import modelo01 from "./modelos/modelo01.vue"

export default defineComponent ({
  components:{
    modelo01
  },
  props:{
    dados:[],
    modelo:{
      default: '1'
    }
  },
  data(){
    return{}
  },
  async beforeMount() {},
  methods: {}
});
</script>
