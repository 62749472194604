<template>
  <section>
    <div class="row" style="background-color: #f8f9fa;border: 1px solid #dee2e6;">

      <div class="field col-12 md:col-6 lg:col-2">
        <label>Pesquisar:</label>
        <input type="text" class="p-inputtext p-component" v-model="buscar_input"  style="width: 100%;" @change="buscarInfo">
      </div>

      <div class="field col-12 md:col-6 lg:col-2" v-if="anoALL">
        <label class="ft">Ano:</label>
        
        <select class="p-inputtext p-component"
          disabled
          style="appearance: revert !important;width: 100% !important;" @change="info.escola_id=0;info.segmento_id=0;info.serie_id=0;info.turma_id=0;"
          v-model="info.ano" >
          <option v-for="a in anoALL" :key="a.ano" :value="a.ano" >{{a.ano}}</option>
        </select>
      </div>

      <div class="field col-12 md:col-2 lg:col-2" v-if="info.ano">
        <label class="ft">Selecione a Escola</label>
        <select class="p-inputtext p-component" v-model="info.escola_id" @change="info.segmento_id=0;info.serie_id=0;info.turma_id=0;buscarSegmentoALL()"
          style="appearance: revert !important;width: 100% !important;" >
          <option value="0" disabled selected> -- Escolha uma Escola -- </option>
          <option v-for="n in escolaALL" :key="n" :value="n.id" >{{n.nome}}</option>
        </select>
      </div>

      <div class="field col-12 md:col-2 lg:col-2" v-if="info.escola_id">
        <label class="ft">Selecione a Segmento</label>
        <select class="p-inputtext p-component" v-model="info.segmento_id" @change="listaSerie(info.segmento_id)"
          style="appearance: revert !important;width: 100% !important;" >
          <option value="0" disabled selected> -- Escolha um Segmento -- </option>
          <option v-for="n in segmentoALL" :key="n" :value="n.id" >{{n.nome}}</option>
        </select>
      </div>

      <div class="field col-12 md:col-1 lg:col-1" v-if="info.segmento_id">
        <label class="ft">Selecione a Série</label>
        <select class="p-inputtext p-component" v-model="info.serie_id" @change="listaTurmas()"
          style="appearance: revert !important;width: 100% !important;" >
          <option value="0" disabled selected> -- Escolha uma serie -- </option>
          <option v-for="n in serieALL" :key="n" :value="n.id" >{{n.nome}}</option>
        </select>
      </div>

      <div class="field col-12 md:col-1 lg:col-2" v-if="info.serie_id">
        <label class="ft">Selecione a Turma</label>
        <select class="p-inputtext p-component" v-model="info.turma_id"
          style="appearance: revert !important;width: 100% !important;" >
          <option value="0" disabled selected> -- Escolha uma Turma-- </option>
          <option v-for="n in turmaALL" :key="n" :value="n.id" >{{n.nome}}</option>
        </select>
      </div>

      <div class="field col-12 md:col-3 lg:col-2" v-if="info.ano" style="margin-top: 31px;">
        <button v-if="aguardeLoading == false"
          class="btn btn-primary" @click="buscarAtas();">
          Pesquisar
        </button>

        <button disabled v-if="aguardeLoading == true" class="btn btn-primary"  >Aguarde...</button>
        <button 
          style="margin-left:5px;"
          class="btn btn-secondary" @click="limparFiltro();">
          Limpar Filtro
        </button>
      </div>
    </div>

  </section>
</template>

<script>

import { defineComponent } from "vue";

import axios from 'axios'
import { Escola } from "@/class/escolas";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { SeriesEscolar } from "@/class/serie.js";
import { Calendario } from "@/class/calendario";
import { Turma } from "@/class/turma";
import { LoginInfo } from "@/class/login";

export default defineComponent ({
  components:{},
  props:{},
  data(){
    return{
      escolaALL:[],
      segmentoALL: [],
      serieALL:[],
      turmaALL:[],
      anoAll:[],
      info:{
        ano:2023,
        escola_id:0,
        segmento_id: 0,
        serie_id: 0,
        turma_id: 0,
      },
      buscar_input: "",
      aguardeLoading: false,
      funcao: 0,
      who:[],
      diretor:[],
      coordenador:[]
    }
  },
  async beforeMount() {
    this.info.ano = parseInt(sessionStorage.getItem("anoSelecionado"));
    await this.logado();
    this.anosCalendario();
    this.buscarEscolaALL();
    this.buscarSegmentoALL();
  },
  methods: {
    limparFiltro(){
      this.info.escola_id = 0;
      this.info.segmento_id = 0;
      this.info.serie_id = 0;
      this.info.turma_id = 0;
    },
    async logado() {
      const token = sessionStorage.getItem("token");
      const whoiam = await LoginInfo.WhoIam(token);

      this.funcao = whoiam.data.funcao;
      this.who = whoiam.data;
    },

    async anosCalendario () {
      const dt = await Calendario.ano();
      this.anoALL = dt.data;
      this.anoALL.sort((a, b) => (a.ano > b.ano) ? 1 : ((b.ano > a.ano) ? -1 : 0));
    },

    /*async buscarEscolaALL() {
      const data = await Escola.obtemTodos();
      this.escolaALL = data.data;
    },*/

    async buscarEscolaALL() {
      this.escolaALL = [];
      //gestores
      if(this.funcao === 1 || this.funcao === 2 || this.funcao === 7){
        const data = await Escola.obtemTodos();
        this.escolaALL = data.data;
      }
      //diretores
      if(this.funcao === 3 || this.who.diretor === 1){

        await axios.get("/diretor/servidor/" + this.who.usuario.id + '/ano/'+ this.info.ano)
          .then((resp) => {
            this.diretor = resp.data.id
          }).catch((err) => {});

        const data = await Escola.obtemUm(this.diretor.escola);
        this.escolaALL.push(data.data)
      }
      //coordenadores
      if(this.funcao === 4){
        const storageEscolas = sessionStorage.getItem("escolas");
        if(!storageEscolas){
          await axios.get("/coordenador/servidor/" + this.who.usuario.id + '/ano/'+ this.info.ano)
            .then((resp) => {
              this.coordenador = resp.data
              sessionStorage.setItem("coordenador_r",JSON.stringify(this.coordenador));
            }).catch((err) => {});

          const escolas = this.coordenador.esc;
          for (let i = 0; i < escolas.length; i++) {
            const data = await Escola.obtemUm(escolas[i]);
            this.escolaALL.push(data.data)
          }
          sessionStorage.setItem("escolas",JSON.stringify(this.escolaALL));
        }else{
          this.escolaALL = JSON.parse(storageEscolas);

          const storageCoordenador = sessionStorage.getItem("coordenador_r");
          this.coordenador = JSON.parse(storageCoordenador);
        }
      }
    },

    /*async buscarSegmentoALL() {
      const data = await SegmentoEscolar.obtemTodos();
      this.segmentoALL = data.data;
    },*/
    async buscarSegmentoALL() {
      if(this.info.escola_id){
        this.segmentoALL = []
        const data = await SegmentoEscolar.obtemSegmentosEscola(this.info.escola_id);
        this.segmentoALL = data.data;
      }else{
        this.segmentoALL = []
      }
        // if(this.funcao === 4){
        //   const data = await SegmentoEscolar.obtemSegmentosEsp();
        //   this.segmentoALL = data.data;
        // }else{
        //   const data = await SegmentoEscolar.obtemTodos();
        //   this.segmentoALL = data.data;
        // }
    },

    async listaSerie (id) {
      this.turma_id = 0;
      this.serie_id = 0;
      //const data = await SegmentoEscolar.listarSegmentosPorSerie(id);
      this.serieALL = [];
      const data = await SegmentoEscolar.listarSegmentosPorSerieExistente(this.info.escola_id,id);
      this.serieALL = data.data;
      return this.seriesALL;
    },

    async listaTurmas(){
      const play = {
        'escola':this.info.escola_id,
        'serie': this.info.serie_id,
        'segmento': this.info.segmento_id,
      };

      const data = await Turma.pesqTurmaPorSerieSegmento(play);
      this.turmaALL = data.data;
    },

    async buscarEscolaShow(id) {
      const data = await Escola.obtemUm(id);
      return data.data.nome;
    },
    async buscarSegmentoShow(id) {
      const data = await SegmentoEscolar.obtemUm(id);
      return data.data.nome;
    },
    async buscarSerieShow(id) {
      const data = await SeriesEscolar.obtemUm(id);
      return data.data.nome;
    },
    async buscarTurmaShow(id) {
      const data = await Turma.obtemUm(id);
      return data.data.nome;
    },

    async buscarInfo(){
     // if(this.buscar_input != ""){
        axios.get('/atas?ano='+this.info.ano+'&buscar_input='+this.buscar_input)
          .then((resp) => {
              this.buscar_input = "";
              this.$emit('buscarAtas', resp.data,0,0)
          }).catch((err) => {});
      //}
    },

    async buscarAtas(){
      if(this.buscar_input == ""){
        try {
          // this.info.escola_nome = await this.buscarEscolaShow(this.info.escola_id);
          // this.info.segmento_nome = await this.buscarSegmentoShow(this.info.segmento_id);
          // this.info.serie_nome = await this.buscarSerieShow(this.info.serie_id);
          // this.info.turma_nome = await this.buscarTurmaShow(this.info.turma_id)

          this.info.escola_nome = this.info.escola_id ? this.info.escola_id : 'undefined';
          this.info.segmento_nome = this.info.segmento_id ? this.info.segmento_id : 'undefined';
          this.info.serie_nome = this.info.serie_id ? this.info.serie_id : 'undefined';
          this.info.turma_nome = this.info.turma_id ? this.info.turma_id : 'undefined';

          this.$emit('buscarAtas', this.info, 1, 1);

        } catch (error) {
          this.aguardeLoading = false;
        }

        this.aguardeLoading = false;
        //this.$emit('buscarAtas', this.info, 1, 1);
        setInterval(() => {
          this.aguardeLoading = false;
        }, 2000);
      }else{
        this.buscarInfo();
      }
    },

  }
});
</script>

<style scoped>
.ft {
  font-size: 15px!important;
}
</style>


