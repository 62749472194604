<template>
  <div class="row">

    <div class="col-md-4">
      <h6 v-if="config.diasletivos">Total de Dias Letivos: {{ head.totaldialetivo }}</h6>
      <h6 v-if="config.escola">Unidade Escola: {{ head.escola_nome }}</h6>
      <h6 v-if="config.aluno">Aluno(a): {{ head.aluno_nome }}</h6>
      <h6 v-if="config.sexo">Sexo: {{ head.sexo }}</h6>
    </div>

    <div class="col-md-4">
      <h6 v-if="config.mae">Mãe: {{ head.mae }}</h6>
      <h6 v-if="config.pai">Pai: {{ head.pai }}</h6>
      <h6 v-if="config.nascimento">Data de Nascimento: {{ head.data_nascimento }}</h6>
      <h6 v-if="config.naturalidade">Naturalidade: {{ head.naturalidade }}</h6>
    </div>

    <div class="col-md-4">
      <h6 v-if="config.endereco">Endereço: {{ head.endereco }}</h6>
      <h6 v-if="config.anoletivo">Ano letivo: {{ head.anoletivo }}</h6>
      <h6 v-if="config.segmento">Segmento Escolar: {{ head.segmento_escolar }}</h6>
      <h6 v-if="config.serie">Ano de Escolaridade: {{ head.ano_de_escolaridade }}</h6>
    </div>

    <div class="col-md-12 header-01" >
      <h6 v-if="config.turma">Turma: {{ head.turma }}</h6>
      <h6 v-if="config.turno">Turno: {{ head.turno }}</h6>
    </div>

  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent ({
  components:{},
  props:{
    config:[],
    head:[]
  },
  data(){
    return{}
  },
  async beforeMount() {},
  methods: {}
});
</script>

<style scoped>
.header-01{
  display: inline-flex; margin-top: 11px;
}
.header-01 h6{
  padding:10px; padding-right:80px;padding-left: 0; border: 0px solid #dee2e6;margin-bottom: 0;
}
</style>
