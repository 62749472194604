<template>
  <section>
    <div class="row" style="background-color: #f8f9fa;border: 1px solid #dee2e6;">


      <div class="field col-12 md:col-4 lg:col-2" v-if="anoALL">
        <label>Ano:</label>
        <select disabled class="p-inputtext p-component"
          style="appearance: revert !important;width: 100% !important;"
          v-model="info.ano" >
          <option v-for="a in anoALL" :key="a.ano" :value="a.ano" >{{a.ano}}</option>
        </select>
      </div>

      <div class="field col-12 md:col-4 lg:col-3" v-if="info.ano">
        <label>Escola</label>
        <select class="p-inputtext p-component" v-model="info.escola_id" @change="info.segmento_id=0;info.serie_id=0;info.turma_id=0;buscarSegmentoALL()"
          style="appearance: revert !important;width: 100% !important;" >
          <option value="0" disabled selected> -- Escolha uma Escola -- </option>
          <option v-for="n in escolaALL" :key="n" :value="n.id" >{{n.nome}}</option>
        </select>
      </div>

      <div class="field col-12 md:col-4 lg:col-3" v-if="info.escola_id">
        <label>Segmento</label>
        <select class="p-inputtext p-component" v-model="info.segmento_id" @change="listaSerie(info.segmento_id)"
          style="appearance: revert !important;width: 100% !important;" >
          <option value="0" disabled selected> -- Escolha um Segmento -- </option>
          <option v-for="n in segmentoALL" :key="n" :value="n.id" >{{n.nome}}</option>
        </select>
      </div>

      <div class="field col-12 md:col-3 lg:col-2"  v-if="info.segmento_id">
        <label>Série</label>
        <select class="p-inputtext p-component" v-model="info.serie_id" @change="listaTurmas()"
          style="appearance: revert !important;width: 100% !important;" >
          <option value="0" disabled selected> -- Escolha uma serie -- </option>
          <option v-for="n in serieALL" :key="n" :value="n.id" >{{n.nome}}</option>
        </select>
      </div>

      <div class="field col-12 md:col-3 lg:col-3"  v-if="info.serie_id">
        <label>Turma</label>
        <select class="p-inputtext p-component" v-model="info.turma_id"
          style="appearance: revert !important;width: 100% !important;" @change="getTurma()" >
          <option value="0" disabled selected> -- Escolha uma Turma-- </option>
          <option v-for="n in turmaALL" :key="n" :value="n.id" >{{n.nome}}</option>
        </select>
      </div>
    </div>

    <filtrosCheckbox @checkout="checkout" :filtro="filtro" v-if="filtro" />
  </section>
</template>

  <script>

  import { defineComponent } from "vue";
  import axios from 'axios';
  import { Escola } from "@/class/escolas";
  import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
  import { Calendario } from "@/class/calendario";
  import { Turma } from "@/class/turma";
  import filtrosCheckbox from "./filtrosCheckbox.vue"
  import { LoginInfo } from "@/class/login";

  import BuscarAluno from "./buscarAluno.vue"

  export default defineComponent ({
    components:{
      BuscarAluno,
      filtrosCheckbox
    },
    props:{
      modelo:0,
      individual:0,
      filtro:[],
    },
    data(){
      return{

        selecao:{
          modelo:'modelo01'
        },
        pesquisar_modelo:false,
        display_pdf:false,
        visualizarorde: false,

        aluno:[],

        segmento_item:[],
        serie_item:[],
        itemS: [],
        escolaALL:[],
        relatorioALL:[],
        segmentoALL: [],
        segmentoALL2: [],
        serieALL:[],
        turmaALL:[],
        anoAll:[],
        info:{
          ano:2023,
          escola_id:0,
          segmento_id: 0,
          aluno: null,
          filtro:null,
          serie_id: null,
          turma_id: null,
          individual: '0',
        },
        segmentos:[],
        serie:{},
        qdt_seg:0,
        todos_segmentos: 1,
        colunas:{
          numero: true
        },
        imprimir:0,
        visivel:false,
        aguardeLoading: false,
        opentable:false,
      }
    },
    async beforeMount() {
      this.info.ano = parseInt(sessionStorage.getItem("anoSelecionado"));
      await this.logado();
      this.anosCalendario();
      this.buscarEscolaALL();
      this.buscarSegmentoALL();
      this.info.filtro = this.filtro;
    },
    methods: {
      async logado() {
        const token = sessionStorage.getItem("token");
        const whoiam = await LoginInfo.WhoIam(token);

        this.funcao = whoiam.data.funcao;
        this.who = whoiam.data;
      },
      async anosCalendario () {
        const dt = await Calendario.ano();
        this.anoALL = dt.data;
        this.anoALL.sort((a, b) => (a.ano > b.ano) ? 1 : ((b.ano > a.ano) ? -1 : 0));
      },

      async buscarEscolaALL() {
        this.escolaALL = [];
        //gestores
        if(this.funcao === 1 || this.funcao === 2 || this.funcao === 7){
          const data = await Escola.obtemTodos();
          this.escolaALL = data.data;
        }
        //diretores
        if(this.funcao === 3){

          await axios.get("/diretor/servidor/" + this.who.usuario.id + '/ano/'+ this.info.ano)
            .then((resp) => {
              this.diretor = resp.data.id
            }).catch((err) => {});

          const data = await Escola.obtemUm(this.diretor.escola);
          this.escolaALL.push(data.data)
        }
        //coordenadores
        if(this.funcao === 4){
          const storageEscolas = sessionStorage.getItem("escolas");
          if(!storageEscolas){
            await axios.get("/coordenador/servidor/" + this.who.usuario.id + '/ano/'+ this.info.ano)
              .then((resp) => {
                this.coordenador = resp.data
                sessionStorage.setItem("coordenador_r",JSON.stringify(this.coordenador));
              }).catch((err) => {});

            const escolas = this.coordenador.esc;
            for (let i = 0; i < escolas.length; i++) {
              const data = await Escola.obtemUm(escolas[i]);
              this.escolaALL.push(data.data)
            }
            sessionStorage.setItem("escolas",JSON.stringify(this.escolaALL));
          }else{
            this.escolaALL = JSON.parse(storageEscolas);

            const storageCoordenador = sessionStorage.getItem("coordenador_r");
            this.coordenador = JSON.parse(storageCoordenador);
          }
        }
      },

      async buscarSegmentoALL() {
        this.segmentoALL = [];
        const data = await SegmentoEscolar.obtemSegmentosEscola(this.info.escola_id);
        this.segmentoALL = data.data;
        /*if(this.funcao === 4){
          const data = await SegmentoEscolar.obtemSegmentosEsp();
          this.segmentoALL = data.data;
        }else{
          const data = await SegmentoEscolar.obtemTodos();
          this.segmentoALL = data.data;
        }*/
      },

      async listaSerie (id) {
        this.turma_id = 0;
        this.serie_id = 0;
        this.serieALL = [];
        //const data = await SegmentoEscolar.listarSegmentosPorSerie(id);
        const data = await SegmentoEscolar.listarSegmentosPorSerieExistente(this.info.escola_id,id);
        this.serieALL = data.data;
        return this.seriesALL;
      },

      async listaTurmas(){
        const play = {
          'escola':this.info.escola_id,
          'serie': this.info.serie_id,
          'segmento': this.info.segmento_id,
        };

        const data = await Turma.pesqTurmaPorSerieSegmento(play);
        this.turmaALL = data.data;
      },


      getTurma(){
        this.$emit('getTurma', this.info);
      },


      buscarAluno(aluno){
        this.info.aluno = aluno;
      },

      newfiltro(filtro){
        this.info.filtro = filtro
      }
    }
  });
  </script>


